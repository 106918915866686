<template>
    <Layout :header-transparency="true" :topbar-style="2" :footer-style="3" buy-button-class="btn-default btn-small">
        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 height-650 bg_image"
             data-black-overlay="2"
             :style="{'background-image': `url(${require(`@/assets/images/bg/web_bg.png`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-left">
                            <h4 class="subtitle" data-aos="fade-up" data-aos-delay="100">
                                <span class="theme-gradient">Schneewolf Labs</span>
                            </h4>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="130">Possibilities Start Here.</h1>
                            <ul class="list-icon" data-aos="fade-up" data-aos-delay="160">
                                <li>
                                    <Icon name="chevron-right" size="20" icon-class="icon"/>
                                    Expertise to scale your software on the cloud.
                                </li>
                                <li>
                                    <Icon name="chevron-right" size="20" icon-class="icon"/>
                                    Custom software and application development.
                                </li>
                                <li>
                                    <Icon name="chevron-right" size="20" icon-class="icon"/>
                                    Innovative solutions for your business.
                                </li>
                            </ul>
                            <!-- <div class="button-group mt--40 mt_sm--20" data-aos="fade-up" data-aos-delay="190">
                                <a class="btn-default btn-icon"
                                   href="mailto:contact@schneewolflabs.com">
                                    Work With Us
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider area  -->

        <!-- Start Service Area  -->
        <div id="services" class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="What we can do for you"
                            title="Bring Knowledge & Experience to Your Organization"
                            description="Consult Schneewolf Labs for any of your software needs - from scaling your application to research and development of something completely new."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceTwo
                    card-style="card-style-1"
                    text-align="left"
                />
            </div>
        </div>
        <!-- End Service Area  -->

        <!-- <Separator/> -->

        <!-- Start About Area  -->
        <!-- <about-two/> -->
        <!-- End About Area  -->

        <!-- <Separator/> -->

        <!-- Start Elements Area  -->
        <!-- <div class="rwt-counterup-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Github"
                            title="We Are Open Source First"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Counter
                    :counter-style="2"
                    text-align="center"
                    :counter-data="counterData"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                />
            </div>
        </div> -->
        <!-- End Elements Area  -->

        <!-- <Separator/> -->

        <!-- Start Elements Area  -->
        <div id="portfolio" class="rwt-team-area rn-section-gap">
            <div class="wrapper plr--65 plr_md--15 plr_sm--15">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Our Portfolio"
                            title="Software We've Built"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData"
                         :key="index">
                        <Team :team-member="teamMember" :team-style="4"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 text-center mt--20">
                <p><a href="https://github.com/Schneewolf-Labs">View our open-source projects on Github!</a></p>
            </div>
        </div>
        <!-- End Elements Area  -->

        <!-- <Separator/> -->

        <!-- Start Elements Area  -->
        <!-- <div class="rwt-pricing-area rn-section-gap">
            <div class="container">
                <div class="row mb--35">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Pricing"
                            title="Our Pricing Plan"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Pricing :pricing-data="pricingData"/>
            </div>
        </div> -->
        <!-- End Elements Area  -->

        <!-- Start Call To Action Area  -->
        <div class="rwt-callto-action-area rn-section-gapBottom">
            <div class="wrapper">
                <CallToAction
                    :style-type="5"
                    title="Ready to start your project?"
                    subtitle="Let's build something great together."
                    btn-text="Contact Us"
                />
            </div>
        </div>
        <!-- End Call To Action Area  -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import ServiceThree from '../../components/elements/service/ServiceThree'
    import Separator from '../../components/elements/separator/Separator'
    import Counter from '../../components/elements/counterUp/Counter'
    import Team from '../../components/elements/team/Team'
    import Pricing from '../../components/elements/pricing/Pricing'
    import CallToAction from '../../components/elements/callToAction/CallToAction'
    import AboutTwo from "../../components/elements/about/AboutTwo";
    import ServiceTwo from "../../components/elements/service/ServiceTwo";

    export default {
        name: 'Corporate',
        components: {
            ServiceTwo,
            AboutTwo, CallToAction, Pricing, Team, Counter, Separator, ServiceThree, SectionTitle, Icon, Layout},
        data() {
            return {
                counterData: [
                    {
                        number: 199,
                        title: '',
                        description: ''
                    },
                    {
                        number: 22,
                        title: 'Public Repositories',
                        description: ''
                    },
                    {
                        number: 69,
                        title: '',
                        description: ''
                    }
                ],
                teamData: [
                    {
                        image: 'luna',
                        name: 'ChatSWL Luna',
                        designation: 'Schneewolf Labs',
                        location: 'Our custom-built AI Assistant and Chat Platform',
                        description: '',
                        link: 'https://chat.schneewolflabs.com'
                    },
                    {
                        image: 'nikuniku900',
                        name: 'NikuNiku900',
                        designation: 'Schneewolf Labs',
                        location: 'Image generator available on Discord and Twitch',
                        description: '',
                        link: 'https://nikuniku900.com'
                    },
                    {
                        image: 'raifuwars',
                        name: 'Raifu Wars',
                        designation: 'Yotis Studios',
                        location: 'A cross-platform online multiplayer game',
                        description: '',
                        link: 'https://raifuwars.com'
                    },
                    {
                        image: 'estoria',
                        name: 'Elements of Estoria',
                        designation: 'Yotis Studios',
                        location: 'Coming soon: an epic online trading card game',
                        description: '',
                        link: '#portfolio'
                    },
                ],
                pricingData: [
                    {
                        title: 'Free',
                        currency: '$',
                        price: '29',
                        subtitle: 'USD Per Month',
                        features: [
                            {title: '5 PPC Campaigns'},
                            {title: 'Digital Marketing'},
                            {title: 'Marketing Agency'},
                            {title: 'Seo Friendly'},
                            {title: 'UI/UX designs'},
                        ],
                        btn: {
                            text: 'Purchase Now',
                            link: '#'
                        }
                    },
                    {
                        title: 'Business',
                        currency: '$',
                        price: '50',
                        subtitle: 'USD Per Month',
                        features: [
                            {title: '5 PPC Campaigns'},
                            {title: 'Keep 100% Royalties'},
                            {title: 'App Development'},
                            {title: 'Seo Friendly'},
                            {title: 'UI/UX designs'},
                        ],
                        btn: {
                            text: 'Purchase Now',
                            link: '#'
                        },
                        isActive: true
                    },
                    {
                        title: 'Advanced',
                        currency: '$',
                        price: '100',
                        subtitle: 'USD Per Month',
                        features: [
                            {title: '5 PPC Campaigns'},
                            {title: 'SEO Marketing'},
                            {title: 'Marketing Agency'},
                            {title: 'Seo Friendly'},
                            {title: 'Application Manage'},
                        ],
                        btn: {
                            text: 'Purchase Now',
                            link: '#'
                        }
                    }
                ],
            }
        }
    }
</script>